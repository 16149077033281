.input-cpn {
    color: white;
    .input {
        background: white;
        color: #707070;
        border: 0;
        font-size: 18px;
        font-weight: 300;
        border-radius: 10px;
        padding-left:10px;
        border-bottom: 1px solid #707070;
        width: 100%;
        height: 50px;
        &:focus{
            outline: none;
        }
    }
    textarea{
        height: 150px!important;
        padding-top: 10px;
    }
}